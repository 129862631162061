import React from "react";
import MarketPlace, { MarketplaceProps } from "components/marketplace/MarketPlace";
import { GetServerSideProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { MINIMUM_QUANTITY, MINIMUM_QUANTITY_LIT } from "@vahak/core/dist/constants/minimumQuantity";
import { MARKET_TYPE } from "@vahak/core/dist/constants/market";
import { LORRY_TYPES } from "@vahak/core/dist/constants/lorryTypes";
import { MARKETPLACE_ROUTE } from "constants/route-constants";
import { CompanyServiceType } from "@vahak/core/dist/_services/auth.service";

function MarketplaceByType({ ...props }: MarketplaceProps) {
    return <MarketPlace {...props} />;
}

export default MarketplaceByType;

function getQtyObjFromParam(qtyParam: string) {
    const intVal = parseInt(qtyParam);
    return intVal > 5
        ? MINIMUM_QUANTITY_LIT.find((qnt) => qnt.id === intVal)
        : MINIMUM_QUANTITY.find((qnt) => qnt.id === intVal);
}

function getLorryTypeFromParam(lorryParam: string) {
    return LORRY_TYPES.find((lorryType) => lorryType.label.toLowerCase() === lorryParam);
}

export const getServerSideProps: GetServerSideProps<MarketplaceProps> = async (context: any) => {
    console.time("mkt_server");
    const { params, l, p = 1, q } = context.query;
    const origin =
        process.env.NEXT_PUBLIC_REACT_APP_ENV == "production" ? "https://vahak.in" : "https://stagingapp.vahak.in";
    const { req } = context;
    const userAgent = req.headers["user-agent"];
    const isBot = /bot|crawler|spider|googlebot|bingbot|msnbot|yandexbot|baiduspider|slurp|duckduckbot/i.test(
        userAgent
    );
    const { cookies } = req;
    const companyId = cookies.ci;
    const isPhoneVerified = cookies.vv;
    const serviceType = cookies.st;

    let srcParam = "";
    let destParam = "";
    const qtyObj = getQtyObjFromParam(q as string);
    const lorryTypeObj = getLorryTypeFromParam(l as string);

    const locationParam = (params?.[1] && params[1].split("-to-")) || "";
    if (locationParam) {
        srcParam = locationParam[0].replace(/-/g, " ");
        destParam = locationParam.length === 2 ? locationParam[1].replace(/-/g, " ") : "";
    }

    // Returning to 404 page for invalid params
    if ((params?.[0] !== MARKET_TYPE.LOAD && params?.[0] !== MARKET_TYPE.Lorry) || params?.length >= 3) {
        return {
            notFound: true
        };
    }

    console.timeEnd("mkt_server");
    return {
        props: {
            ...(await serverSideTranslations(context.locale, ["common", "marketplace"])),
            marketplaceType: params?.[0],
            urlPage: parseInt(p as string) || null,
            urlSrc: srcParam,
            urlDest: destParam,
            urlFilters:
                qtyObj || lorryTypeObj
                    ? JSON.parse(
                          JSON.stringify({
                              qty: qtyObj,
                              lorryType: lorryTypeObj
                          })
                      )
                    : null,
            data: null,
            isMobile: userAgent?.includes("Mobile"),
            isLoggedIn: Boolean(companyId),
            isPhoneVerified: Boolean(isPhoneVerified),
            serviceType: (serviceType as CompanyServiceType) || null,
            isBot,
            route: MARKETPLACE_ROUTE
        }
    };
};
